/*
        ----- Important Notice -----
        This code is the intellectual property of Laxab Digital Solutions Pvt Ltd (Regd company in India,
        hereafter referred as 'company'). If you are not an employee this company, you are not authorized 
        to copy, save, or transmit this code in any form or medium without prior written permission from 
        the company.

        For more information, please visit company's website: www.laxab.com/intellectualproperty

        ----->> Business SCSS <<-----

        Version 1.0 : 09 Jun 2023, Created      ***Current version***
*/

$topBarHeight:100px;
$gap:40px;
$grey:#dddddd;
$priBg:#171a29;
$green:#900C3F;
$orange:#B5275E;
$bodyColor:#e9ecee;
$delay:0.35s;

body {
    font-family: 'default';
    flex-grow: 1;
    margin:0px;
    background: $bodyColor;
    //background-image: url('../../images/bg/3.jpg');
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
}
.stdMargin {margin:$gap}
.stdMargin_2 {margin:$gap/2;}
.stdPadding {padding:$gap}
.stdPadding_2 {padding:$gap/2}
.stdDiv1 {padding:$gap/5 $gap/2 $gap/5}
.stdDiv2 {margin:$gap/5 $gap/2 $gap/5}
.stdButton {border:0px solid #cccccc;width:calc(100% - 30px);padding:15px;background:$green;color:white;cursor:pointer;transition: $delay;font-size: 15px;}
.stdButton:hover {background: lighten($green, 10);transition: $delay;}
.stdButtonDisabled {border:0px solid #cccccc;width:calc(100% - 30px);padding:15px;background:$grey;color:#888;transition: $delay;}
.deleteButton {height: 30px; width: 30px; margin: 0px $gap/3 0px; border-radius: 50%; color:white;background: $grey; transition: $delay;text-align: center;justify-content: center;}
.deleteButton:hover {background: $orange; transition: $delay; cursor: pointer;}
.addCartGreen{height:20px;width: 30px;padding:5px;background: $grey;transition: $delay;}
.addCartGreen:hover{background: $green;color:white;transition: $delay; cursor: pointer;}
.addCartOrange{height:20px;width: 30px;padding:5px;background: $grey;transition: $delay;}
.addCartOrange:hover{background: $orange;color:white;transition: $delay; cursor: pointer;}
.addCartText{height:20px;width: 30px;padding:5px;background: $grey;cursor: pointer;}
.stdRow{
    border:1px solid $grey;
    display:flex;
    justify-Content:center;
    border-radius: 0px;
    margin-top:20px;
}
.stdRowElements {
    border:0px solid $grey;
    border-right:0px solid $grey;
    border-bottom:0px solid $grey;
    padding:10px 15px 10px;
    height:auto;
    border:0px solid $grey;
    padding:10px 15px 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
}
.stdTableFcFrTitle {
    border:1px solid $green;
    border-right:0px solid $grey;
    border-bottom:0px solid $grey;
    padding:10px 15px 10px;
    background: $green;
    color:white;
}
.stdTableFcLrTitle {
    border:1px solid $green;
    border-bottom:0px solid $grey;
    padding:10px 15px 10px;
    background: $green;
    color:white;
}
.stdTableFcFr {
    border:0px solid $grey;
    border-right:0px solid $grey;
    border-bottom:0px solid $grey;
    padding:10px 15px 10px;
}
.stdTableHighlight {
    border:0px solid $grey;
    background: $grey;
    padding:10px 15px 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
}
.stdTableFcLr {
    border:0px solid $grey;
    border-bottom:0px solid $grey;
    padding:10px 15px 10px;
}

.stdTableLcFr {
    border:1px solid $grey;
    border-right:0px solid $grey;
    padding:10px 15px 10px;
}
.stdTableLcLr {
    border:1px solid $grey;
    padding:10px 15px 10px;
}
.titleBar{
    border-bottom:0px solid $grey;
    width:calc(100%);
    display: flex;
    justify-content: center;
    padding:$gap/2 0px $gap/2;
    background: white;
}
.titleBarName{

    width:100%;
    text-align: left;
    float: left;
    height:100%;
    display: flex;
    float: center;
    margin-top: auto; /* Push the child div to the top */
    margin-bottom: auto; /* Push the child div to the bottom */
}
.closeButton{
    color: $grey;
    cursor: pointer;
    text-align: center;
    font-size: 40px;
    float: right;
    top:0;
    right:0;
    margin-top: auto; /* Push the child div to the top */
    margin-bottom: auto; /* Push the child div to the bottom */
    transition: $delay;
}
.closeButton:hover{
    color: $orange;
    transition: $delay;
}
.backbone{
    border:0px dashed #999999;
    width:80%;
    display:flex;
    //flex-wrap: wrap;  //<-- This is to get CHILD on new line
}
.root {
    display: flex;
    height:80px;
}
.body .component {
    padding:20px;
}
h1 {margin: 0px;padding:0px;font-weight: bold;}
h2 {margin: 0px;padding:0px;font-weight: bold}

// ----- Top Menu -----
.topMenuBackbone{
    height:calc($topBarHeight - 1px);
}
.topMenu{
    height:calc($topBarHeight - 1px);
    position: fixed;
}
.topMenuButton{
    margin:0px 0px 0px $gap;
    border:0px dashed #cccccc;
    cursor: pointer;
}
.topMenuButton:hover{
    color:$orange;
}
.topmenuoptionshide{ visibility: hidden; }
.topmenuoptionsshow{ 
    display:flex;
}
.mainTitle{
    width: auto;
    padding: $gap/2.6 $gap/1.4 $gap/2.6;
    margin-top:$gap;
    color:white;
    background: #282c3f;
    //box-shadow: 0 3px 5px 0 rgba(40,44,63,.4);
    //box-shadow: 0 1px 1px 0 #555555, 0 1px 10px 0 #555555;
    font-size: large;
    display: flex;
    align-items: center;
    margin: auto 0px auto;
}

// ----- Banner -----
.bannerCanvas{
    background:linear-gradient($priBg, $priBg);
    height:auto;
    width: 100%;
}
.banner_insights{
    margin:$gap calc($gap/1.6) $gap;
    height:230px;
    width:250px;
    background:#dddddd;
    transition: transform $delay ease;
    justify-content: center;
    display: flex;
    align-items: center;
}
.banner_insights:hover{
    transform: scale(1.08);
    cursor: pointer;
}
.banner_custom{
    margin:25px 15px 25px;
    padding:0px;
    background:#ffffffb8;
    transition: $delay ease;
    border:0px solid #F6FAF6;
    display: flex;
    transition: $delay;
}
.banner_custom:hover{
    cursor: pointer;
    //box-shadow: 1px 1px 18px #cccccc;
    background:#ffffff;
    //box-shadow: 0 0px 0px 0 #cccccc, 0 0px 15px 0 #cccccc;
    border:0px solid #c3c8c8;
    transition: $delay;
}
.bannerLabels{
    display:flex;
    align-items:center;
    margin:auto 3px auto;
    height:auto;
    width:auto;
    border:0px solid #dddddd;
    background: #EAEEF0;
    justify-content:center;
    padding:5px 20px 5px;
    border-radius: 20px;
}
.banner_custom_left{
    border-right:0px dashed #999999;
    height:258px;
    width:150px;
    justify-content: center;
    background: #eeeeee;
}
.banner_custom_right{
    border:0px dashed #666666;
    height:248px;
    width:200px;
    justify-content: center;
}
.bannerButton{
    border: 1px solid #E5E9EB;
    width: calc(100% - 50px);
    color:#333333;
    margin:0px 15px 10px;
    padding: 10px;
    color:$orange;
}
.bannerButton:hover{
    border: 1px solid $orange;
    background: $orange;
    color:#ffffff;
}

// ----- Cart -----
.cartStatus {
    width: 830px;
    height: 50px;
    background: #60b246;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    cursor: pointer;
    padding:0px $gap 0px;
    z-index: 3;
}
.cartStatus div{
    float: right;
    height:100%;
    display: flex;
    align-items: center;
    margin: 0px;
    width:25%;
    text-align: center;
    justify-content: center;
    transition: $delay;
}
.cartStatus div:hover{
    background: linear-gradient( #579B41,#579B41);
    transition: $delay;
}
.cartRed{
    background: darken($green,10);
    transition: $delay;
}
.cartCircle{
    background: $green;
    color:white;
    height:10px;
    width:33px;
    margin:0px;
    justify-content: center;
    margin-left: 5px;
    border-radius: 50%;
}      
.cartView{
    width: 830px;
    height: auto;
    border-top: 0px solid $grey;
    border-right: 0px solid $grey;
    border-left: 0px solid $grey;
    background: white;
    position: fixed;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 1px 1px 0 #000000, 0 15px 80px 0 #000000;
    padding: 0px $gap 0px;
    z-index: 3;
}
.cartViewBody{
    height: 400px;
    overflow-y: auto;
    padding:0px $gap 0px;
    background: white;
    margin:0px;
}

// ----- Footer -----
.footerBase {
    height: auto;
    width: 100%;
    background: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
.footerColumn {
    border: 0px dashed #999999;
    padding: 0px 0px $gap/2;
    margin: $gap calc($gap / 2) $gap;
    width: 20%;
    background: linear-gradient(#000000, #111111);
    justify-content: top;
    align-items: left;
    display: flex;
    flex-direction: column;
    line-height: 25px;
    letter-spacing: 0.6px;
}

.footerRow {
    border-top: 3px solid #111111;
    padding-top: $gap/3;
    margin: 0px 0px $gap;
    width: 70%;
    line-height: 25px;
    letter-spacing: 0.6px;
    color:white;
    font-size: small;
    text-align: center;
}

.footerContents{
    color:white;
    font-size: small;
    padding:$gap/2 $gap/2 0px;
}
  
$colPri1A:#ffffff;
$colPri1B:darken($colPri1A,12);
$colborderL:#e7e6e6;
$colborderB:#e7e6e6;
$sliderBorderR:transparent;
$shadow1A:rgba(0, 0, 0, 0.2);
$shadow1B:rgba(0, 0, 0, 0.2);
$textcolor1A:#000000;
$gradA:#471680;
$gradB:#53074d;
$gradA:#ffffff;
$gradB:#ffffff;


.line{
    border-bottom: 1px solid $colPri1B;
    width: 100%;
    padding-bottom: $gap/2.6;
}
.formik_field{
    color:red;
}
.topMenu {
    border-bottom: 0px solid $colPri1B;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 8px 0px 30px -5px rgba(0, 0, 0, 0.5),-8px 0px 10px -5px rgba(0, 0, 0, 0.5);
    background: #ffffff;
    position: fixed;
    width:100%;

}
.topMenu_left{
    font-weight: bold;
    width:30%;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    border: 0px solid $colPri1B;
}
.topMenu_right{
    width:70%;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    border: 0px solid $colPri1B;
}
.topMenu_right .icon {
    background: #471680;
    width:35px;
    height: 35px;
    border-radius: 50%;
    text-align: right;
    float: right;
    margin-right: $gap;
}
.topMenu_right .ic {
    background: #eeeeee;
    width:25px;
    height: 25px;
    text-align: right;
    float: right;
    margin-right: $gap;
}
.topMenu_right div{
    float: right;
    height:35px;
    display: flex;
    align-items: center;
}
.sideNavButton_toggle{
    padding-top: calc($gap/2.5);
    padding-bottom: calc($gap/2.5);
    border:0px solid;
    text-align:right;
    display:flex;
    align-items:center;
}
.sideNavButton_toggle:hover{
    background: #eeeeee;
}
.sideNavMax{ 
    flex-shrink: 0;
    height: calc(100vh - $topBarHeight);
    width:180px;
    transition:width $delay;
    background-image: linear-gradient($gradA, $gradB);
    z-index: 100;
    cursor: pointer;
}
.sideNavMax .sideNavButton{
    padding: $gap 0px $gap;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $colPri1B;
    text-decoration: none;
    color:$textcolor1A;
}
.sideNavMax .sideNavButton:hover{
    background: #eeeeee;
}
.sideNavMax .icon{
    background: #cccccc;
    width:30px;
    height: 30px;
    text-align: right;
    float: right;
    margin: 0px $gap 0px;
}
.sideNavMin{ 
    flex-shrink: 0;
    height: calc(100vh - $topBarHeight);
    width:calc($gap + 30px + $gap);
    transition:width $delay;
    background-image: linear-gradient($gradA, $gradB);
    z-index: 100;
    cursor: pointer;
}
.sideNavMin .sideNavButton{
    padding: $gap 0px $gap;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $colPri1B;
}
.sideNavMin .sideNavButton:hover{
    background: #eeeeee;
}
.sideNavMin .sideNavButton .text{
    display: none;
}
.sideNavMin .icon{
    background: #cccccc;
    width:30px;
    height: 30px;
    text-align: right;
    float: right;
    margin: 0px $gap 0px;
}
.body{
    flex-grow: 1;
    height:calc(100vh - $topBarHeight);
    //background: $colPri1B;
    color:darken($color: $textcolor1A, $amount: 15);
    //box-shadow: 0 4px 8px 0 $shadow1A, 0 6px 20px 0 $shadow1B;
    //box-shadow: 0 -10px 20px 0 $shadow1A, 0 6px 20px 0 $shadow1B;
    border-left: 1px solid $colPri1B;;
    z-index: 200;
}
.backgroundGreen {background: $green; color:white;}
.orangeBg {background: $orange;color:white}
.orangeBorder {border-color: $orange;}
.orangeBorder:hover {border-color: $orange;}
.listedButton{width:100%;height:80px;display: flex;border-bottom: 1px solid #F3F1F3;}
.listedButton:hover { background: #f2eeee; cursor:pointer;transition:0.2s}




.effect1{
    -webkit-box-shadow: 0 10px 6px -6px #777;
       -moz-box-shadow: 0 10px 6px -6px #777;
            box-shadow: 0 10px 6px -6px #777;
  }
  .effect2
  {
    position: relative;
  }
  .effect2:before, .effect2:after
  {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width:300px;
    background: #777;
    -webkit-box-shadow: 0 15px 10px #777;
    -moz-box-shadow: 0 15px 10px #777;
    box-shadow: 0 15px 10px #777;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  .effect2:after
  {
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
    right: 10px;
    left: auto;
  }
  
  /*==================================================
   * Effect 3
   * ===============================================*/
  .effect3
  {
    position: relative;
  }
  .effect3:before
  {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width:300px;
    background: #777;
    -webkit-box-shadow: 0 15px 10px #777;
    -moz-box-shadow: 0 15px 10px #777;
    box-shadow: 0 15px 10px #777;
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  
  /*==================================================
   * Effect 4
   * ===============================================*/
  .effect4
  {
    position: relative;
  }
  .effect4:after
  {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 15px;
    right: 10px;
    left: auto;
    width: 50%;
    top: 80%;
    max-width:300px;
    background: #777;
    -webkit-box-shadow: 0 15px 10px #777;
    -moz-box-shadow: 0 15px 10px #777;
    box-shadow: 0 15px 10px #777;
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  
  /*==================================================
   * Effect 5
   * ===============================================*/
  .effect5
  {
    position: relative;
  }
  .effect5:before, .effect5:after
  {
    z-index: -1;
    position: absolute;
    content: "";
    bottom: 25px;
    left: 10px;
    width: 50%;
    top: 80%;
    max-width:300px;
    background: #000;
    -webkit-box-shadow: 0 35px 25px #000;
    -moz-box-shadow: 0 35px 25px #000;
    box-shadow: 0 35px 25px #000;
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  .effect5:after
  {
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    -o-transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    transform: rotate(8deg);
    right: 10px;
    left: auto;
  }
  
  /*==================================================
   * Effect 6
   * ===============================================*/
  .effect6
  {
      position:relative;
      -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
         -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
              box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  }
  .effect6:before, .effect6:after
  {
    content:"";
      position:absolute;
      z-index:-1;
      -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
      -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
      box-shadow:0 0 20px rgba(0,0,0,0.8);
      top:50%;
      bottom:0;
      left:10px;
      right:10px;
      -moz-border-radius:100px / 10px;
      border-radius:100px / 10px;
  }
  .effect6:after
  {
    right:10px;
      left:auto;
      -webkit-transform:skew(8deg) rotate(3deg);
         -moz-transform:skew(8deg) rotate(3deg);
          -ms-transform:skew(8deg) rotate(3deg);
           -o-transform:skew(8deg) rotate(3deg);
              transform:skew(8deg) rotate(3deg);
  }
  
  /*==================================================
   * Effect 7
   * ===============================================*/
  .effect7
  {
      position:relative;
      -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
         -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
              box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  }
  .effect7:before, .effect7:after
  {
    content:"";
      position:absolute;
      z-index:-1;
      -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
      -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
      box-shadow:0 0 20px rgba(0,0,0,0.8);
      top:0;
      bottom:0;
      left:10px;
      right:10px;
      -moz-border-radius:100px / 10px;
      border-radius:100px / 10px;
  }
  .effect7:after
  {
    right:10px;
      left:auto;
      -webkit-transform:skew(8deg) rotate(3deg);
         -moz-transform:skew(8deg) rotate(3deg);
          -ms-transform:skew(8deg) rotate(3deg);
           -o-transform:skew(8deg) rotate(3deg);
              transform:skew(8deg) rotate(3deg);
  }
  
  /*==================================================
   * Effect 8
   * ===============================================*/
  .effect8
  {
      position:relative;
      -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
         -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
              box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  }
  .effect8:before, .effect8:after
  {
    content:"";
      position:absolute;
      z-index:-1;
      -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
      -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
      box-shadow:0 0 20px rgba(0,0,0,0.8);
      top:10px;
      bottom:10px;
      left:0;
      right:0;
      -moz-border-radius:100px / 10px;
      border-radius:100px / 10px;
  }
  .effect8:after
  {
    right:10px;
      left:auto;
      -webkit-transform:skew(8deg) rotate(3deg);
         -moz-transform:skew(8deg) rotate(3deg);
          -ms-transform:skew(8deg) rotate(3deg);
           -o-transform:skew(8deg) rotate(3deg);
              transform:skew(8deg) rotate(3deg);
  }
  .textStyle{
    font-size: 72px;
    background: -webkit-linear-gradient(120deg, #325654, #335553);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }